@use "breakpoints";

*:focus {
  outline: none !important;
}

a {
  text-decoration: none;
}

button {
  &:focus-visible {
    box-shadow: var(--tab-focus) !important;
  }
}

.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  line-height: var(--body-line-height);
  font-weight: var(--body-font-weight);
  padding: var(--btn-padding);
  color: var(--n99);
  background-color: transparent;
  font-size: var(--body-font-size);
  border-radius: 0;
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    cursor: initial;
    pointer-events: none;
    &:not(.btn-link) {
      opacity: 0.3;
    }
  }
  &.focus-visible,
  &:focus-visible {
    box-shadow: var(--tab-focus) !important;
    &:not(.btn-switch) {
      background-color: var(--n0) !important;
    }
    border-color: transparent !important;
    &:not(.btn-delete) {
      color: var(--n99) !important;
    }
  }
  transition: var(--btn-transition);
}

.btn-primary,
a.btn-primary {
  color: var(--n0);
  background-color: var(--n100);
  border-color: var(--n100);
  &:active {
    color: var(--n0);
    background-color: var(--n90);
    border-color: var(--n90);
  }
  @media (hover: hover) {
    &:hover {
      color: var(--n0);
      background-color: var(--n90);
      border-color: var(--n90);
    }
  }
  &:visited {
    color: var(--n0);
    background-color: var(--n90);
    border-color: var(--n90);
  }
}

.btn-secondary {
  color: var(--n100);
  background-color: var(--n0);
  border-color: var(--n100);
  &:active {
    color: var(--n0);
    background-color: var(--n100);
    border-color: var(--n100);
  }
  @media (hover: hover) {
    &:hover {
      color: var(--n0);
      background-color: var(--n100);
      border-color: var(--n100);
    }
  }
  &:visited {
    color: var(--n100);
    background-color: var(--n0);
    border-color: var(--n100);
  }
}

.btn-link {
  color: var(--n100);
  &:active {
    color: var(--n90);
  }
  &:visited {
    color: var(--n90);
  }
  @media (hover: hover) {
    &:hover {
      color: var(--n90);
    }
  }
  &:disabled,
  &.disabled {
    color: var(--disabled-text);
    background-color: transparent;
    border-color: transparent;
  }
  &-blue {
    color: var(--blue-4);
    &:active {
      color: var(--blue-5);
    }
    &:visited {
      color: var(--blue-4);
    }
    @media (hover: hover) {
      &:hover {
        color: var(--blue-5);
      }
    }
  }
  &-red {
    color: var(--red-5);
    &:active {
      color: var(--red-4);
    }
    &:visited {
      color: var(--red-4);
    }
    @media (hover: hover) {
      &:hover {
        color: var(--red-4);
      }
    }
  }
}

.btn-box {
  color: var(--n100);
  background-color: var(--n0);
  border-color: var(--n50);
  min-height: 124px;
  &:active {
    color: var(--n0);
    background-color: var(--n100);
    border-color: var(--n100);
  }
  @media (hover: hover) {
    &:hover {
      color: var(--n0);
      background-color: var(--n100);
      border-color: var(--n100);
    }
  }
  &:visited {
    color: var(--n100);
    background-color: var(--n0);
    border-color: var(--n50);
  }
}

.btn-outline {
  color: var(--n100);
  border-color: var(--n100);
  &:active {
    border-color: var(--n100);
    background-color: var(--n100);
    color: var(--n0);
  }
  @media (hover: hover) {
    &:hover {
      border-color: var(--n100);
      background-color: var(--n100);
      color: var(--n0);
    }
  }
  &:visited {
    color: var(--n100);
    border-color: var(--n100);
  }
}

.btn-cancel {
  color: var(--red-5);
  background-color: var(--n0);
  border-color: var(--red-5);
  &:active {
    color: var(--n0);
    background-color: var(--red-5);
  }
  @media (hover: hover) {
    &:hover {
      background-color: var(--red-5);
      color: var(--n0);
    }
  }
  &:visited {
    color: var(--n0);
    background-color: var(--n0);
    border-color: var(--red-5);
  }
}

.btn-back {
  @media (hover: hover) {
    &:hover {
      opacity: 0.6;
    }
  }
}
