@use "background";

// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

:root {
  --ion-font-family: greycliff-cf, sans-serif;
  --ion-toolbar-background: var(--n0);
  --swiper-pagination-color: var(--n0);
  --ion-tab-bar-color: var(--n90);
  --ion-background-color: var(--n0);
  --logo: "assets/";
  --textarea-radius: 32px;
}

html {
  --ion-dynamic-font: var(--ion-default-dynamic-font);
  --ion-font-family: greycliff-cf, sans-serif;
}

.loading-wrapper {
  --background: transparent;
  --spinner-color: #0071e6;
}

.tab-selected,
ion-tab-button:hover,
ion-tab-button.a:hover {
  .text {
    color: var(--n100);
  }
  .icon {
    background: linear-gradient(254deg, var(--g2-start) 11.4%, var(--g2-stop) 88.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

ion-tab-bar {
  --border: 0.55px solid rgb(0 0 0 / 10%);
}

.camera {
  video {
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    margin-inline: auto;
  }
}

ion-progress-bar {
  --background: rgb(0 0 0 / 10%);
  --progress-background: var(--orange-3);
  height: 3px;
}

ion-modal {
  --ion-background-color: var(--n0);
  --width: 100%;
  --height: 100%;
  &.modal-card {
    --max-width: 100%;
    --max-height: 100%;
  }
  &.dialog-modal {
    --width: calc(100% - 24px);
    --max-width: 600px;
    --height: fit-content;
    --border-radius: 16px;
  }
}

ion-header {
  ion-toolbar {
    --border-color: rgb(0 0 0 / 10%);
    &:first-of-type {
      padding-top: var(--ion-toolbar-padding-top);
    }
  }
}

ion-grid {
  --ion-grid-width-md: 700px;
  --ion-grid-width-lg: 700px;
  --ion-grid-width-xl: 700px;
}

ion-content {
  transition: opacity 0.2s ease-in;
}

.tab-loading {
  ion-content {
    opacity: 0;
  }
}

ion-spinner {
  width: var(--loader-size, 28px);
  height: var(--loader-size, 28px);
}

ion-back-button {
  --icon-font-size: 24px;
  transition: var(--btn-transition);
}
