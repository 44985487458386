@use "variables";
@use "sass:meta";

:root {
  // Colors

  // Generate palettes for full colors, grays, and theme colors.
  @each $color, $value in variables.$colors {
    --#{$color}: #{$value};
  }

  // Fonts

  // Note: Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --font-sans-serif: #{meta.inspect(variables.$font-family-sans-serif)};

  // Root and body
  // scss-docs-start root-body-variables
  @if variables.$font-size-root {
    --root-font-size: #{variables.$font-size-root};
  }
  --body-font-family: #{variables.$font-family-base};
  --body-font-size: #{variables.$font-size-base};
  --body-font-weight: #{variables.$font-weight-base};
  --body-line-height: #{variables.$line-height-base};
  --body-color: #{variables.$body-color};
  @if variables.$body-text-align {
    --body-text-align: #{variables.$body-text-align};
  }
  --body-bg: var(--n0);
  --box-shadow: #{variables.$box-shadow};
  --tab-focus: #{variables.$tab-focus};
  --disabled-text: var(--n50);
  --disabled-bg: var(--n50);
  --btn-padding: #{variables.$btn-padding};
  --btn-lg: #{variables.$btn-lg};
  --btn-transition: #{variables.$btn-transition};
  --border-radius: #{variables.$border-radius};
  --border-radius-sm: #{variables.$border-radius-sm};
  --border-radius-lg: #{variables.$border-radius-lg};
  --border-radius-pill: #{variables.$border-radius-pill};
  --border-radius-max: #{variables.$border-radius-max};
  --input-transition: #{variables.$input-transition};
}

// generating background and text color classes based on theme-colors
@each $color, $value in variables.$colors {
  .text-#{$color} {
    color: $value !important;
  }
}
@each $color, $value in variables.$colors {
  .bg-#{$color} {
    background-color: $value !important;
  }
}
