/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * greycliff-cf:
 *   - http://typekit.com/eulas/00000000000000007735fa48
 *   - http://typekit.com/eulas/00000000000000007735fa4d
 *
 * © 2009-2024 Adobe Systems Incorporated. All Rights Reserved.
 */
@font-face {
  font-family: greycliff-cf;
  src: url("https://use.typekit.net/af/ea5834/00000000000000007735fa48/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
    format("woff2");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}
@font-face {
  font-family: greycliff-cf;
  src: url("https://use.typekit.net/af/31979f/00000000000000007735fa4d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
    format("woff2");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

.tk-greycliff-cf {
  font-family: greycliff-cf, sans-serif;
}
