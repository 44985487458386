/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@use "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@use "@ionic/angular/css/normalize.css";
@use "@ionic/angular/css/structure.css";

/* App specific css */
@use "variables";
@use "root";
@use "reboot";
@use "utilities-api";
@use "fonts/fonts";
@use "fonts/typography";
@use "fonts/icomoon";
@use "elements/buttons";

#be-frame.be-frame-minimized {
  border-radius: 50%;
  width: 60px !important;
  height: 60px !important;
  bottom: 6rem !important;
}
