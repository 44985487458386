@font-face {
  font-family: icomoon;
  src: url("../../fonts/icomoon.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icomoon, sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-support::before {
  content: "\e928";
}

.icon-edit-pen::before {
  content: "\e926";
}

.icon-heart::before {
  content: "\e925";
}

.icon-mic::before {
  content: "\e923";
}

.icon-stop-circle::before {
  content: "\e924";
}

.icon-close-gray .path1::before {
  content: "\e91f";
  color: #ebe7e6;
}

.icon-close-gray .path2::before {
  content: "\e920";
  color: #5d5458;
  margin-left: -1em;
}

.icon-back-gray .path1::before {
  content: "\e921";
  color: #ebe7e6;
}

.icon-back-gray .path2::before {
  content: "\e922";
  color: #1a1618;
  margin-left: -1em;
}

.icon-arrow-right-fill::before {
  content: "\e91c";
}

.icon-checkmark-fill::before {
  content: "\e91d";
}

.icon-call::before {
  content: "\e91b";
}

.icon-chat::before {
  content: "\e919";
}

.icon-directions::before {
  content: "\e918";
}

.icon-calendar::before {
  content: "\e91a";
}

.icon-close-fill::before {
  content: "\e917";
}

.icon-add-fill::before {
  content: "\e916";
}

.icon-money::before {
  content: "\e900";
}

.icon-care::before {
  content: "\e901";
}

.icon-location::before {
  content: "\e902";
}

.icon-home::before {
  content: "\e903";
}

.icon-user-active::before {
  content: "\e904";
}

.icon-user-pending::before {
  content: "\e905";
}

.icon-user::before {
  content: "\e906";
}

.icon-take-photo::before {
  content: "\e907";
}

.icon-edit::before {
  content: "\e908";
}

.icon-question-outline::before {
  content: "\e909";
}

.icon-search::before {
  content: "\e90a";
}

.icon-checkmark-outline::before {
  content: "\e90b";
}

.icon-checkmark::before {
  content: "\e90c";
}

.icon-carret-right::before {
  content: "\e90d";
}

.icon-carret-down::before {
  content: "\e90e";
}

.icon-play-outline::before {
  content: "\e90f";
}

.icon-play::before {
  content: "\e910";
}

.icon-plus-outline::before {
  content: "\e911";
}

.icon-close::before {
  content: "\e912";
}

.icon-arrow-right::before {
  content: "\e913";
}

.icon-arrow-left::before {
  content: "\e914";
}

.icon-plus::before {
  content: "\e915";
}

.icon-envelope::before {
  content: "\e91e";
}

.icon-camera-off::before {
  content: "\e929";
}

.icon-camera-on::before {
  content: "\e92a";
}

.icon-mic-off::before {
  content: "\e92b";
}

.icon-mic-on::before {
  content: "\e92c";
}

.icon-settings-outline::before {
  content: "\e92d";
}

.icon-end-call::before {
  content: "\e91cc";
}

.icon-shrink::before {
  content: "\e939";
}

.icon-expand::before {
  content: "\e93a";
}

.icon-info-outline::before {
  content: "\e943";
}
