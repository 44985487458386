.card-border {
  border: 1px solid var(--n40);
}

.background {
  .header-background {
    -webkit-backdrop-filter: unset;
    backdrop-filter: unset;
  }
  .card-border {
    border: none;
  }
}

// Background gradient classes

.g4 {
  background: linear-gradient(93deg, var(--g4-start) 0%, var(--g4-stop) 100%);
}
