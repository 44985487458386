@use "sass:math";

// generating font-weight classes
@for $weight from 3 through 7 {
  .fw-#{$weight}00 {
    font-weight: calc(#{$weight} * 100) !important;
  }
}

// generating font-size classes in rems
@for $font-size from 6 through 60 {
  $font-size-rem: math.div($font-size, 16);
  .fs-#{$font-size} {
    font-size: #{$font-size-rem}rem !important;
  }
}

// generating text-trim classes
@for $lines from 1 through 5 {
  .text-trim-#{ $lines } {
    display: -webkit-box !important;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

// heading classes

.heading-72 {
  line-height: 112%;
  letter-spacing: -1.44px;
  font-size: 72px;
}

.heading-64 {
  line-height: 120%;
  letter-spacing: -3.2px;
  font-size: 56px;
}

.heading-56 {
  line-height: 112%;
  letter-spacing: -1.12px;
  font-size: 56px;
}

.heading-48 {
  line-height: 112%;
  letter-spacing: -2.4px;
  font-size: 48px;
}

.heading-40 {
  line-height: 104%;
  letter-spacing: -0.8px;
  font-size: 40px;
}

.heading-36 {
  line-height: 112%;
  letter-spacing: -1.44px;
  font-size: 36px;
}

.heading-32 {
  line-height: 120%;
  letter-spacing: -0.96px;
  font-size: 32px;
}

.heading-24 {
  line-height: 120%;
  letter-spacing: -0.96px;
  font-size: 24px;
}

.heading-20 {
  line-height: 120%;
  letter-spacing: -0.72px;
  font-size: 20px;
}

.p-20 {
  line-height: 136%;
  font-size: 20px;
}

.p-18 {
  font-size: 18px;
  line-height: 136%;
}

// Text gradient

.g1 {
  background: linear-gradient(254deg, var(--g1-start) 11.4%, var(--g1-stop) 88.6%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.g2 {
  background: linear-gradient(254deg, var(--g2-start) 11.4%, var(--g2-stop) 88.6%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.first-letter-uppercase {
  &::first-letter {
    text-transform: uppercase;
  }
}

.first-letter-lowercase {
  &::first-letter {
    text-transform: lowercase;
  }
}
